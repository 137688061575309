import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { initI18nLean, SECTION_POST_LIST } from '@wix/communities-blog-client-common';
import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import {
  getLanguage,
  getIsMobile,
  isEditor,
  getIsICUEnabled,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import PostList from '../post-list';
import '../../../rich-content/components/rich-content-editor/rich-content-pg.global.scss';
import styles from './app-root.scss';
import { isInWix } from '../../../common/services/is-in-wix';
import {
  getIsCreatedWithResponsiveEditor,
  getLayoutMargins,
  getUseMobileDesignSettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';

class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (this.props.isEditor && this.props.createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }

  getI18nConfig = () => {
    const { language, translations, isICUEnabled } = this.props;

    if (!this.i18nConfig) {
      this.i18nConfig = initI18nLean(language, translations, isICUEnabled);
    }

    return this.i18nConfig;
  };

  render() {
    const { padding, useMobileDesign, FrameComponent, getPostListPosts } = this.props;
    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <I18nextProvider i18n={i18n}>
          <div style={{ padding }} className={useMobileDesign ? styles.mobileBaseStyles : styles.baseStyles}>
            <FrameComponent>
              <PostList getPostListPosts={getPostListPosts} />
              <AppLoaded />
            </FrameComponent>
          </div>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  FrameComponent: PropTypes.elementType,
  getPostListPosts: PropTypes.func,
  isICUEnabled: PropTypes.bool,
};

AppRoot.defaultProps = {
  FrameComponent: React.Fragment,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isEditor: isEditor(state),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  useMobileDesign: getUseMobileDesignSettings(state),
  padding:
    (getIsMobile(state) && !getUseMobileLayoutSettings(state)) || getIsCreatedWithResponsiveEditor(state)
      ? 0
      : getLayoutMargins({
          state,
          section: SECTION_POST_LIST,
          layoutType: getLayoutType(state, SECTION_POST_LIST, getUseMobileLayoutSettings(state)),
        }),
  isICUEnabled: getIsICUEnabled(state),
});

export default connect(mapRuntimeToProps)(AppRoot);
